@font-face {
  src: local(Goldplay-Regular),
  url(./assets/fonts/Goldplay/Goldplay-Regular.woff) format('woff'),
  url(./assets/fonts/Goldplay/Goldplay-Regular.eot) format('eot'),
  url(./assets/fonts/Goldplay/Goldplay-Regular.ttf) format('truetype');
  font-family:Goldplay;
}

@font-face {
  src: local(Goldplay-Light),
  url(./assets/fonts/Goldplay/Goldplay-Light.woff) format('woff'),
  url(./assets/fonts/Goldplay/Goldplay-Light.eot) format('eot'),
  url(./assets/fonts/Goldplay/Goldplay-Light.ttf) format('truetype');
  font-family: GoldplayLight;
}

@font-face {
  src: local(Goldplay-Medium),
  url(./assets/fonts/Goldplay/Goldplay-Medium.woff) format('woff'),
  url(./assets/fonts/Goldplay/Goldplay-Medium.eot) format('eot'),
  url(./assets/fonts/Goldplay/Goldplay-Medium.ttf) format('truetype');
  font-family: GoldplayMedium;
}

@font-face {
  src: local(Goldplay-Black),
  url(./assets/fonts/Goldplay/Goldplay-Black.woff) format('woff'),
  url(./assets/fonts/Goldplay/Goldplay-Black.eot) format('eot'),
  url(./assets/fonts/Goldplay/Goldplay-Black.ttf) format('truetype');
  font-family: GoldplayBlack;
}

@font-face {
  src: local(DMSerifDisplay-Regular), url(./assets/fonts/DMSerifDisplay/DMSerifDisplay-Regular.ttf) format('truetype');
  font-family: 'DM Serif Display';
}

@font-face {
  src: local(DMSerifDisplay-Italic), url(./assets/fonts/DMSerifDisplay/DMSerifDisplay-Italic.ttf) format('truetype');
  font-family: 'DM Serif Display Italic';
}

html, body, #root {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Goldplay', 'GoldplayLight', 'GoldplayMedium', 'GoldplayBlack',
  'DM Serif Display', 'DM Serif Display Italic', Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7000;
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.react-toast-notifications__container {
  z-index: 9000 !important;
  overflow: inherit !important;
}

.react-toast-notifications__toast {
  min-height: 65px !important;
  /*align-items: center;*/
  /*color: #ffffff !important;*/
  /*font-family: 'GoldplayMedium',sans-serif;*/
  /*font-style: normal;*/
  /*font-weight: bolder;*/
}

/*.react-toast-notifications__toast--success {*/
/*  background-color: #A46EDD !important;*/
/*}*/

/*.react-toast-notifications__toast--error {*/
/*  background-color: #A46EDD !important;*/
/*}*/

/*.react-toast-notifications__toast__icon-wrapper {*/
/*  background-color: #A46EDD !important;*/
/*  !*background-color: #efe6f9 !important;*!*/
/*}*/

/*.react-toast-notifications__toast__content {*/
/*  font-size: 15px !important*/
/*}*/

/*.react-toast-notifications__toast__dismiss-button {*/
/*  opacity: unset !important;*/
/*}*/
